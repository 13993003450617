import React from "react";
import PropTypes from "prop-types";
import { navigate, Link, graphql } from "gatsby";
import loadable from "@loadable/component";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import WhiteButton from "../components/v2/WhightButton";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Hidden from "@material-ui/core/Hidden";

// All Images
import salesManagementImg from "../images/v2/home/features-images/sales-management.svg";
import integrationImg from "../images/v2/home/features-images/integration.svg";
import securityImg from "../images/v2/home/features-images/security.svg";
import webformsImg from "../images/v2/home/features-images/webforms.svg";
import analyticsAndInsites from "../images/v2/home/features-images/analytics-and-insites.svg";
import dashboardImg from "../images/v2/home/features-images/dashboard.svg";
import leadCapture from "../images/v2/home/features-images/lead-capture.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import remoteReadyBG from "../images/v2/home/remote-ready-bg.svg";
import responsiveBG from "../images/v2/home/responsive-bg-1.webp";
import integrationBG from "../images/v2/home/integration-bg.svg";
import crmInsightsBgImg from "../images/v2/home/crm-insights-bg.svg";
import whatsappIcon from "../images/v2/campaigns/social.png";
import whatsappMessage from "../images/v2/home/features-images/what's-app-message.svg";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";
import CompanyCard from "../components/v2/CompanyCard";
import whatsAppChat from "../animations/whatsAppChat.json";
import LazyLoad from "react-lazyload";
import Lottie from "lottie-react";

// import WebinarModal from "../components/v2/WebinarModal";

const VideoModal = loadable(() => import("../components/v2/VideoModal"));

const gradient = "linear-gradient(241deg, #b3e8f5 100%, #b3e8f5 53%, #a6d8ed)";

const useStyles = makeStyles((theme) => ({
  homeSliderText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
    fontWeight: 600,
  },
  customButton: {
    fontSize: "22px",
  },
  explorButton: {
    boxShadow:
      " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    border: "1px solid green",
    "&:hover": {
      border: "1px solid green",
    },
  },
  clientLogoImg: {
    width: "100%",
    height: "auto",
    padding: "0 1rem",
  },
  cycleProcessImg: {
    "& picture img": {
      willChange: "auto !important",
    },
    // maxWidth: "530px",
    // height: "auto",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  imgLogo: {
    height: "86px",
    width: "86px",
    [theme.breakpoints.down("xs")]: {
      height: "40px",
      width: "40px",
    },
  },
  crmInsightsimg: {
    maxWidth: "480px",
    height: "auto",
  },
  crmheadimg: {
    width: "100%",
    height: "auto",
  },
  crmheadimgContainer: {
    marginBottom: "-45px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  crmheadingsubimg: {
    textAlign: "center",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  featureItemContainer: {
    listStyleType: "none",
    padding: "0px",
  },
  featureItem: {
    display: "block",
    cursor: "pointer",
    border: "2px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e7f2ff",
    lineHeight: "22px",
    padding: theme.spacing(2),
    minHeight: "370px",
    "&:hover": {
      background: "#f2f9f9",
      borderColor: "#f2f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "380px",
      padding: "1rem",
      "& h3": {
        fontSize: "22px",
      },
    },
  },
  featureItemText: {
    fontSize: "16px !important",
  },
  remoteReadyRoot: {
    background: `url(${remoteReadyBG})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  responsiveRoot: {
    background: `url(${responsiveBG}) center top`,
    marginTop: theme.spacing(-14),
    // backgroundSize: "cover",
    minHeight: "690px",
     paddingTop: "180px",
    [theme.breakpoints.down("sm")]: {
       marginTop: theme.spacing(0),
      backgroundImage: gradient,
       paddingTop: "32px",
      backgroundSize : "cover",
      minHeight : "614px"
    },
  },
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  collapseDivider: {
    backgroundColor: "#7ad2e2",
  },
  expendButton: {
    backgroundColor: "#5ab6cf",
    marginBottom: theme.spacing(1),
    "&:hover": {
      background: "#a1dae2",
    },
  },
  numberBorder: {
    borderRight: "1px solid #e5e7e9",
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: theme.spacing(3),
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  blogCard: {
    boxShadow: "0 15px 20px 0 rgba(118, 130, 183, 0.18)",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginTop: -2,
  },
  IntegratedCRMBg: {
    background: "linear-gradaent(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  ConnectCrm: {
    background: "linear-gradient(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  WhatsAppCrm: {
    background: "linear-gradient(1deg, #dbe9f9 30%, #fff 117%)",
  },
  svg: {
    "& svg g g rect": {
      fill: "none",
    },
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center top`,
  },
  crmInsightsBg: {
    // background: `url(${crmInsightsBgImg}) left top no-repeat`,
    background: "linear-gradient(179deg, #eaf4fe -6%, #fff 40%)",
  },
  iconContainer: {
    display: "flex",
    columnGap: "100px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      columnGap: "10px",
      marginLeft: "29px",
      marginTop: "-64px",
    },
  },
  smeSupportImg: {
    width: "461.2px",
    height: "79.3px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
  formSubmit: {
    fontSize: "16px",
    fontWeight: "600",
  },
  listItemText: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 36,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginRight: theme.spacing(1.5),
    },
  },
  sliderTestimonialImg: {
    boxShadow: "7px 20px 20px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
  },
  cimg: {
    "& .gatsby-image-wrapper": {
      filter: "grayscale(100%)",
      "&:hover": {
        filter: "none",
      },
    },
  },
  link: {
    cursor: "pointer",
  },
  getStartedBtn: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  videoContainer: {
    position: "relative",
  },
  "@keyframes smallScale": {
    from: {
      transform: "scale(1)",
      opacity: 1,
    },
    to: {
      transform: "scale(1.5)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes triangleStroke": {
    from: {
      strokeDashoffset: 90,
    },
    to: {
      strokeDashoffset: 0,
    },
  },

  videoBtnCircle: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    backgroundColor: "#4cbb83",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    padding: "0px",
    top: "62.5%",
    left: "35.5%",
    zIndex: "2",
    "&:before, &:after": {
      content: '""',
      background: "rgba(76, 187, 131, 0.5)",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      width: "100%",
      animationName: "$smallScale",
      animationDuration: "3s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    },
    "&:after": {
      animationDelay: "0.5s",
      zIndex: -1,
    },
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "30px",
    height: "30px",
    transform: "translate(-50%, -50%)",
    "& .triangle": {
      animation: `$fadeIn 1.5s ${theme.transitions.easing.easeIn}`,
    },
    "& .path": {
      strokeDasharray: 90,
      strokeDashoffset: 0,
      animation: "$triangleStroke 1.5s",
      animationTimingFunction: `${theme.transitions.easing.easeIn}`,
    },
  },
  hasScaleAnimation: {
    animationName: "$smallScale",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  bold: {
    fontWeight: "700",
    color: "#333333",
  },
  responsiveImg : {
     marginBottom: '-65px',
     [theme.breakpoints.down("sm")] : {
      // marginBottom: '-50px',
      marginTop: '-25px',
      marginRight : '35px',
     }
  },
  rowImages: {
    display: 'flex',
    gap: theme.spacing(2),
    },
  qrScanner : {
    width: '132px',
    height: '132px',
    objectFit: 'contain',
    [theme.breakpoints.down("sm")] : {
    width: '60px',
    height: '60px',
    marginTop : "25px",
    },
  },
   googlePlay : {
    width: '200px',
    height: '60px',
    objectFit: 'contain',
    marginTop : "40px",
    [theme.breakpoints.down("sm")]: {
      width: '100px',
      height: '30px',
  },
  },
  iOSAppStore : {
    width: '200px',
    height: '60px',
    objectFit: 'contain',
    marginTop : "40px",
    [theme.breakpoints.down("sm")]: {
      width: '100px',
      height: '30px',

  },
  },
  stayConnected : {
     marginTop : "40px",
    [theme.breakpoints.down("sm")] : {
      marginTop : "-10px",
    },
  },
  equip : {
    [theme.breakpoints.down("sm")] : {
      marginTop : "-25px",
      marginBottom : "-15px",
    },
  },
}));

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud+TechData Corporation"
      layout="constrained"
      src="../images/v2/testimonials-images/yoon-kam-fei-peppercloud-customer.png"
    />
  ),
  name: "Yoon Kam Fei",
  position: "Managing Director",
  company: "Tec D Distribution Sdn Bhd, Malaysia",
  content: (
    <React.Fragment>
      Team collaboration, accurate sales forecasting, and effective resource
      management are the challenges every organisation encounters. But having an
      efficient CRM tool like Pepper Cloud enhances productivity while assisting
      in optimising the sales process and managing the sales pipeline.
      <br />
      <br />
      Pepper Cloud CRM tool is the right mix of flexibility, affordability, and
      ease of use.
    </React.Fragment>
  ),
};

const features = [
  {
    title: "Messaging",
    description:
      "Connect and communicate with your leads effortlessly. With our multichannel integration, converse from a single platform. Assign chats to the team members as per the need and provide personalised responses with our Facebook and Whatsapp integration.",
    logo: whatsappMessage,
    href: "/multichannel-integration-for-sales-marketing",
    alt: "WhatsApp CRM system",
  },
  {
    title: "Lead capture",
    description:
      "Capture your leads directly into the CRM software from integrated web forms or digital ads. Automatically, add them to your sales pipeline and convert them into your customers while cutting down on repetitive tasks.",
    logo: leadCapture,
    href: "/lead-capture-generation-webform",
    alt: "CRM system to capture sales leads",
  },
  {
    title: "Sales management",
    description:
      "Organise your sales funnel and stay in control of your business  with easy customisable sales pipelines. Gain complete visibility into sales activities and drive your team towards winning opportunities. Manage all your sales data from a unified platform and forecast your future revenue.",
    logo: salesManagementImg,

    href: "/sales-pipeline-management-tool",
    alt: "Sales growth with the best CRM system",
  },
  {
    title: "Integrations",
    description:
      "Integrate with your favourite apps and bring harmony to your daily activities. With plug-and-play integrations of Pepper Cloud, sync your marketing tools, calendars, emails, accounting software, video conferencing tools, messaging apps, and more.",
    logo: integrationImg,
    href: "/integration",
    alt: "Sales with CRM system integration",
  },
  {
    title: "Analytics and insights",
    description:
      "Get a bird’s eye view of your business performance with an interactive dashboard. Customise it at your convenience and access in-depth insights on your leads, activities, and campaigns.",
    logo: analyticsAndInsites,
    href: "/sales-dashboard",
    alt: "Sales CRM dashboard",
  },
  {
    title: "Security",
    description:
      "Gain absolute data protection and security control through vulnerability-tested CRM software that offers data encryption, user access management, and two-factor authentication.",
    logo: securityImg,
    href: "/crm-data-security-protection",
    alt: "Secured CRM software",
  },
];

// Avoid duplication or unnecessary data entry.
// Understand the reasons for losing opportunities by analysing your processes and sales strategies.
// Identify your staff training needs by reviewing your sales process, and the associated activities.

const security =
  `Sync your communication channels and expedite sales with instant messaging.
Arm your team with the CRM system to win more opportunities with the least manual work.
Track all the tasks and associated activities and refocus their efforts when necessary. 
Instantly acknowledge the top performers of your team and derive the best practices for everyone.
Reason your won and lost opportunities by analysing your processes and sales strategies.`
    .split(`
`);

const Home = (props) => {
  const width = useWidth();
  const classes = useStyles();

  const [showVideo, setShowVideo] = React.useState(false);
  const [activePipeline, setActivePipeline] = React.useState(0);

  const pipeline = React.useMemo(() => {
    const {
      data: { featureEngage, featureManage, featureAutomate, featureExpand },
    } = props;
    const pipeline = [
      {
        title: "Engage",
        description: (
          <>
            Engage effectively with your prospects and customers by sending
            emails and conversing through WhatsApp and Facebook, right from your
            CRM system. Track and record the activities by keeping a timeline
            view of every interaction with your clients. Add, edit, and complete
            new sales activities on the go.
          </>
        ),
        img: getImage(featureEngage),
        alt: "Engage more customer with best CRM with social media integration",
      },
      {
        title: "Manage",
        description: (
          <>
            Manage your business with an efficient{" "}
            <a href="https://peppercloud.com/sales-pipeline-management-tool">
              {" "}
              sales pipeline management
            </a>{" "}
            feature. Build multiple sales pipelines and customise stages in the
            pipelines as per your needs. Identify, add, and categorise new leads
            easily and quickly. Save time and team efforts by eliminating
            repetitive tasks. Stay on top of your activities and follow-ups with
            utmost convenience.
          </>
        ),
        img: getImage(featureManage),
        alt: "Manage business with sales pipeline management tool",
      },
      {
        title: "Automate",
        description: (
          <>
            With{" "}
            <a href="https://peppercloud.com/integration">
              {" "}
              powerful CRM system integrations
            </a>
            , sync your favourite tools to capture leads automatically, generate
            quotations, set up video meetings, and keep track of your processes
            and activities in real-time. Eliminate repetitive tasks with
            ultimate CRM capabilities. Automate your sales progress with the
            Pepper Cloud CRM tool.
          </>
        ),
        img: getImage(featureAutomate),
        alt: "Automate everything with powerful CRM system integrations",
      },
      {
        title: "Expand",
        description:
          "Increase sales referrals with the help of existing customers. Improve products and services with the help of your customers’ feedback. Discover opportunities to upsell and cross-sell to your existing customers with the help of existing customer information and actionable in-depth insights.\n",
        img: getImage(featureExpand),
        alt: "Expand business with Best Sales CRM",
      },
    ];

    return pipeline;
  }, []);

  const toggleVideoModal = () => setShowVideo((st) => !st);

  return (
    <React.Fragment>
      <SEO
        description="Escalate your small sales business with best sales CRM software. Leverage features like sales & marketing automation, powerful CRM integrations, lead generation, analytics and more."
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
        keywords="Best sales CRM software , sales crm software for small business,Singapore CRM,CRM system,CRM software,sales CRM software,Sales CRM system,Sales CRM Solution"
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        title="Best Sales CRM Software Built for Small Businesses | Pepper Cloud"
      />
      <Box pt={8}>
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            {/*<Box alignItems="center" component={Grid} display="flex" flexDirection="column" item md={6} sm={12}>*/}
            <Grid item lg={5} md={5} sm={12}>
              <Box className={classes.homeSliderText}>
                <Box
                  alignItems="center"
                  display="flex"
                  height={24}
                  mb={2}
                  mt={2}
                >
                  <Box
                    alt="CRM with WhatsApp and Faceboook Messenger Integration"
                    component={"img"}
                    maxWidth={"100%"}
                    src={whatsappIcon}
                  />
                  <ParagraphTypography
                    className={classes.smeBannerTxthead}
                    fontSize={18}
                    fontWeight={500}
                    m={0}
                    // pr={{ sm: "0px", xs: "0px" }[width] || "8rem"}
                  >
                    &nbsp; CRM with WhatsApp and Facebook integration
                  </ParagraphTypography>
                </Box>
                <HeaderTypography
                  component="h1"
                  fontSize={40}
                  fontWeight={600}
                  mt={0}
                  mb={2}
                >
                  All-in-one sales CRM built just for your sales team
                </HeaderTypography>

                <ParagraphTypography mb={5} mt={0}>
                  Grow your sales with Singapore's best all-in-one CRM system,
                  built for small and medium businesses. Connect WhatsApp,
                  Facebook Messenger, Instagram, Telegram, and other messaging
                  channels with the CRM platform and close deals faster!
                </ParagraphTypography>
                <Box className={classes.getStartedBtn} mb={7}>
                  <Button
                    className={classes.customButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                  <Hidden smUp>
                    <Box>
                      <Button
                        className={classes.loginButton}
                        color="secondary"
                        // fullWidth
                        href="https://app.peppercloud.com"
                        rel="noreferrer"
                        size="large"
                        target="_blank"
                        variant="outlined"
                      >
                        Login
                      </Button>
                    </Box>
                  </Hidden>
                </Box>
              </Box>
            </Grid>
            <Grid
              component={Box}
              item
              lg={7}
              maxWidth="100%"
              md={7}
              paddingLeft="20px"
              sm={12}
            >
              <Box className={classes.crmheadimgContainer}>
                <StaticImage
                  alt="Sales CRM Singapore"
                  className={classes.crmheadimg}
                  placeholder="blurred"
                  src="../images/v2/home/homepage-hero.webp"
                />
              </Box>
              {/* <div className={classes.crmheadingsubimg}>
                <StaticImage
                  alt="IMDA + SMEs Go Digital + Pepper Cloud CRM"
                  placeholder="blurred"
                  src="../images/v2/home/smeSupport(2x).png"
                  className={classes.smeSupportImg}
                />
              </div> */}
            </Grid>
          </Grid>
        </Container>
        <Box mb={2} mt={1}>
          <Divider light />
        </Box>
      </Box>
      <Box>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          my={{ md: 4 }}
          textAlign="center"
        >
          <HeaderTypography component="h2" fontWeight={600} mb={0} mt={2}>
            Completely connected to your business
          </HeaderTypography>
          <ParagraphTypography mb={8} mt={3}>
            Grow your business by integrating your CRM with popular
            communication channels - WhatsApp, Facebook, Telegram, Instagram, and
            more.
          </ParagraphTypography>
          <Grid container spacing={6}>
            <Grid item md={2} />
            <Grid item md={8} xs={10} className={classes.iconContainer}>
              <div>
                <StaticImage
                  alt="WhatsApp + Pepper Cloud CRM Integration"
                  className={classes.imgLogo}
                  m={6}
                  src="../images/v2/home/whatsapp_logo.webp"
                />
              </div>
              <div>
                <StaticImage
                  alt="Twilio + Pepper Cloud CRM Integration"
                  className={classes.imgLogo}
                  m={6}
                  src="../images/v2/home/twillio_logo.webp"
                />
              </div>
              <div>
                <StaticImage
                  alt="Facebook + Pepper Cloud CRM Integration"
                  className={classes.imgLogo}
                  m={6}
                  src="../images/v2/home/Facebook-Logo.webp"
                />
              </div>
              <div>
                <StaticImage
                  alt="Telegram + Pepper Cloud CRM Integration"
                  className={classes.imgLogo}
                  m={6}
                  src="../images/v2/home/telegram_logo.webp"
                />
              </div>
              <div>
                <StaticImage
                  alt="Instagram + Pepper Cloud CRM Integration"
                  className={classes.imgLogo}
                  m={6}
                  src="../images/v2/home/Instagram_logo.webp"
                />
              </div>
            </Grid>
          </Grid>
          <Box mb={4} mt={8} textAlign={{ sm: "center", xs: "center" }[width]}>
            <Button
              className={classes.bookNowButton}
              color="secondary"
              component={Link}
              size="large"
              to="/multichannel-integration-for-sales-marketing"
              variant="contained"
            >
              Explore Channels
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt={1} mb={0}>
        <Divider light />
      </Box>
      <Box className={classes.WhatsAppCrm}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          pt={2}
          mt={0}
          // my={{ md: 4 }}
          textAlign="center"
        >
          <HeaderTypography
            component="h2"
            fontWeight={600}
            maxWidth={900}
            marginBottom="0"
            // mb={{ sm: 3, xs: 3 }[width] || 10}
            // mt={{ md: 3 }}
          >
            Manage sales communication with the best WhatsApp CRM platform
          </HeaderTypography>
          <ParagraphTypography mb={6} mt={2}>
            Automate lead capture, personalise customer communication, and
            improve sales productivity with WhatsApp-enabled CRM.
          </ParagraphTypography>
          <Grid container spacing={6}>
            <Grid item md={12} xs={12}>
              <div className={classes.svg}>
                <LazyLoad offset={100} once>
                  <Lottie
                    animationData={whatsAppChat}
                    autoplay={true}
                    loop={true}
                    style={{
                      height: "100%",
                      background: "transparent",
                      fill: "none",
                      marginTop: "-49px",
                    }}
                  />
                </LazyLoad>
              </div>
            </Grid>
          </Grid>
          <Box mb={8} mt={6} textAlign={{ sm: "center", xs: "center" }[width]}>
            <Button
              color="secondary"
              component={Link}
              size="large"
              to="/whatsapp-crm"
              variant="contained"
            >
              WhatsApp CRM
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        mb={6}
        py={3}
        textAlign="center"
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <HeaderTypography
          aria-controls="pc-category-list"
          aria-haspopup="false"
          component="h2"
          fontWeight={600}
          id="pc-category"
          mb={0}
          mt={3}
        >
          Simple, yet a powerful CRM tool
        </HeaderTypography>

        <ParagraphTypography component="h3" maxWidth={720} mb={8} mt={2}>
          Sell faster. Nurture better. Support smarter.
          <br />
          Leverage all the features of the best sales CRM software and grow your
          business multifold.
        </ParagraphTypography>

        <Grid
          aria-labelledby="pc-category"
          className={classes.featureItemContainer}
          component="ul"
          container
          id="pc-category-list"
          justify="center"
          role="menu"
          spacing={4}
        >
          {features.map((each) => (
            <Grid
              component="li"
              item
              key={each.title}
              md={4}
              role="none"
              sm={6}
              xs={12}
            >
              <Box
                alt={each.description}
                className={classes.featureItem}
                onClick={() => navigate(`https://peppercloud.com/${each.href}`)}
                role="menuitem"
              >
                <Box
                  alt={each.alt || each.title}
                  component="img"
                  mt={2}
                  src={each.logo}
                  width={{ sm: 60, xs: 60 }[width] || 75}
                />
                <HeaderTypography fontSize={22} fontWeight={600} my={2}>
                  {each.title}
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.featureItemText}
                  color="text.paragraph"
                  lineHeight="26px"
                  m={0}
                >
                  {each.description}
                </ParagraphTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={1} mb={0}>
        <Divider light />
      </Box>

      <Box>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          my={{ md: 7 }}
          textAlign="center"
        >
          <HeaderTypography
            component="h2"
            fontWeight={600}
            maxWidth={900}
            // mb={{ sm: 3, xs: 3 }[width] || 10}
            mt={{ md: 0 }}
          >
            CRM Solution - Trusted by many businesses across different
            industries
          </HeaderTypography>
          <CompanyCard className={classes.cimg} />
        </Box>
      </Box>

      <Box
        alignItems="center"
        className={classes.remoteReadyRoot}
        display="flex !important"
        flexDirection="column"
        mt={6}
        pb={{ ms: 3, xs: 3 }[width] || 10}
        pt={3}
        textAlign="center"
      >
        <Container>
          <Box display="flex" justifyContent="center">
            <Box maxWidth={835}>
              <HeaderTypography
                color="common.white"
                component="h2"
                fontWeight={600}
                letterSpacing={0.25}
                lineHeight={1.25}
                mb={3}
                mt={6}
              >
                Sell more with Singapore’s best sales CRM software
              </HeaderTypography>
              <ParagraphTypography
                color="common.white"
                fontSize={18}
                mb={6}
                mt={0}
              >
                Identify new opportunities, build invaluable business
                relationships with your customers, and convert the leads to won
                opportunities effortlessly. With the best sales CRM system of
                Singapore, designed for small and medium businesses (SMEs), you
                can empower your sales team to create happy customers and
                experience the next-gen product for scaling your business.
              </ParagraphTypography>
            </Box>
          </Box>
        </Container>
        <Grid container justify="center" spacing={8}>
          <Grid item md={6} sm={12}>
            <Box
              display="flex"
              justifyContent={
                { sm: "center", xs: "center" }[width] || "flex-end"
              }
            >
              <Box m={0} maxWidth={600} p={1}>
                {pipeline.map((each, index) => (
                  <div key={each.title}>
                    <Box m={0} px={2}>
                      <Box
                        alignItems="center"
                        color="common.white"
                        display="flex"
                        justifyContent="space-between"
                        my={1}
                        onClick={() =>
                          setActivePipeline(
                            index === activePipeline ? -1 : index
                          )
                        }
                      >
                        <HeaderTypography
                          color="common.white"
                          fontSize={26}
                          fontWeight={500}
                          lineHeight={1.39}
                          m={0}
                        >
                          {each.title}
                        </HeaderTypography>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="inherit"
                          m={0}
                        >
                          {index !== activePipeline ? (
                            <AddIcon fontSize="small" m={0} />
                          ) : (
                            <RemoveIcon fontSize="small" m={0} />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activePipeline === index}>
                        <ParagraphTypography
                          borderColor="#7ad2e2"
                          // borderTop={1}
                          color="common.white"
                          fontSize={16}
                          mt={0}
                          pt={2}
                          textAlign="justify"
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                      {index + 1 !== pipeline.length && (
                        <Divider className={classes.collapseDivider} light />
                      )}
                    </Box>
                  </div>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              display="flex"
              justifyContent={
                { sm: "center", xs: "center" }[width] || "flex-start"
              }
            >
              <GatsbyImage
                alt={
                  activePipeline !== -1
                    ? pipeline[activePipeline].alt
                    : pipeline[0].alt
                }
                image={
                  activePipeline !== -1
                    ? pipeline[activePipeline].img
                    : pipeline[0].img
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={width === "sm" || width === "xs" ? "" : classes.smartCrmTool}
      >
        <Box component={Container}>
          <Box display="flex" justifyContent="center">
            <Box pb={3}>
              <Box display="flex" justifyContent="center">
                <Box
                  maxWidth={800}
                  pb={{ sm: 2, xs: 2 }[width] || 4}
                  pt={4}
                  textAlign="center"
                >
                  <HeaderTypography
                    alignItems="center"
                    component="h2"
                    display="flex"
                    fontWeight={600}
                    justifyContent="center"
                    lineHeight={1.2}
                    mb={3}
                  >
                    Smart CRM tool to simplify your sales team's efforts
                  </HeaderTypography>
                  <ParagraphTypography
                    component="h3"
                    fontSize={18}
                    lineHeight={1.5}
                    mb={0}
                  >
                    Manage your team seamlessly with the best CRM software. Chat
                    with your prospects, Organise your sales funnel,
                    <a href="https://peppercloud.com/sales-dashboard">
                      {" "}
                      access real-time updates
                    </a>
                    , and gain an overview of your team’s performance.
                  </ParagraphTypography>
                </Box>
              </Box>
              <Grid alignItems="center" container justify="center" spacing={2}>
                <Grid item alignItems="center" md={6} sm={12} xs={12}>
                  <Box pr={{ sm: 1, xs: 1 }[width] || 8}>
                    <List>
                      {security.map((each) => (
                        <ListItem alignItems="center" key={each}>
                          <ListItemIcon>
                            <CheckIcon className={classes.listItemIcon} />
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText}>
                            {each}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    justifyContent={
                      { sm: "center", xs: "center" }[width] || "flex-end"
                    }
                  >
                    <StaticImage
                      alt="Cut short Sales cycle with best crm for sales reps"
                      className={classes.cycleProcessImg}
                      placeholder="blurred"
                      src="../images/v2/home/sales-team-efforts@2x.webp"
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <br />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.crmInsightsBg} pb={2} pt={8}>
        <Container>
          <Grid
            alignItems="center"
            container
            justify="center"
            spacing={{ sm: 2, xs: 2 }[width] || 10}
          >
            <Grid item md={6} sm={12} xs={12}>
              <Box p={{ sm: 2, xs: 2 }[width] || 6} textAlign="center">
                <StaticImage
                  alt="Improve sales performance with best sales crm"
                  className={classes.crmInsightsimg}
                  placeholder="blurred"
                  src="../images/v2/home/crm-insights@2x.png"
                />
              </Box>
            </Grid>
            <Grid item md={1} sm={12} xs={12} />
            <Grid item md={5} sm={12} xs={12}>
              <HeaderTypography
                component="h2"
                fontSize={40}
                fontWeight={600}
                lineHeight={1.25}
                mt={0}
              >
                Unlock success with the best sales CRM
              </HeaderTypography>
              <HeaderTypography
                component="h3"
                fontSize={28}
                fontWeight={600}
                mb={3}
                mt={0}
              >
                Uncover insights |{(width === "sm" || width === "xs") && <br />}{" "}
                Increase revenues |
                {(width === "sm" || width === "xs") && <br />} Manage
                expectations
              </HeaderTypography>
              <ParagraphTypography fontSize={18}>
                Pepper Cloud CRM lets you have a seamless product experience so
                that you can focus on building business relationships with your
                customers. Create a personalised customer journey by empowering
                your team with the best sales CRM so that they not only sell but
                also create happy and satisfied customers.
              </ParagraphTypography>
              <Box pt={2}>
                <Button
                  className={classes.bookNowButton}
                  color="secondary"
                  component={Link}
                  size="large"
                  to="/contact"
                  variant="contained"
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.responsiveRoot} mt={0} pb={8}>
        <Container>
          <Grid alignItems="flex-start" container justify="center" spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <HeaderTypography
                color="common.black"
                component="h2"
                fontSize={40}
                fontWeight={600}
                lineHeight={1.5}
                my={{ sm: 6, xs: 6 }[width] || 4}
                textAlign="left"
                className= {classes.stayConnected}
              >
                Stay connected with <br/> mobile CRM
              </HeaderTypography>
              <ParagraphTypography
                color="common.black"
                component="h3"
                fontSize={18}
                maxWidth={720}
                mb={2}
                textAlign="left"
                className= {classes.equip}
              >
                Equip your sales team to close deals on the go with the Pepper Cloud mobile app. Converse with leads and customers across various messaging apps, receive custom notifications, access comprehensive client profiles, and automate chat interactions.
              </ParagraphTypography>

              <Box fontSize="18px" fontWeight="600" mb={1} mt={1} className={classes.footerimgs}>

            <Box className={classes.rowImages}>
            <a href="https://play.google.com/store/apps/details?id=com.peppercloud" target="_blank" rel="noopener noreferrer">
            <StaticImage
            alt="Google Play"
            placeholder="blurred"
            src="../../../images/v2/google-play-image.webp"
            className={classes.googlePlay}
            />
            </a>
            <a href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415" target="_blank" rel="noopener noreferrer">
            <StaticImage
            alt="iOS App Store"
            placeholder="blurred"
            src="../../../images/v2/iOS-App-Store.webp"
            className={classes.iOSAppStore}
            />
            </a>
              <StaticImage
                alt="Image 3"
                placeholder="blurred"
                src="../../../images/v2/Qr-Scanner-footer.png"
                className={classes.qrScanner}
              />
            </Box>
            </Box>
            </Grid>

            <Grid item md={1} sm={12} />
            <Grid item md={5} sm={12}>
              <Box textAlign={{ sm: "center", xs: "center" }[width] || "right"}>
                <StaticImage
                  alt="Responsive mobile crm system"
                  className={classes.responsiveImg}
                  placeholder="blurred"
                  src="../images/v2/home/mobile-design-homepage.webp"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {showVideo && (
        <VideoModal
          handleClose={toggleVideoModal}
          src="https://youtu.be/_9E9EE8OQ6w"
        />
      )}

      <Container id="testimonial">
        <Box display="flex" justifyContent="center">
          <Box
            maxWidth={800}
            pb={{ sm: 2, xs: 2 }[width] || 6}
            pt={4}
            textAlign="center"
          >
            <HeaderTypography
              alignItems="center"
              component="h2"
              display="flex"
              fontSize={40}
              fontWeight={600}
              justifyContent="center"
              lineHeight={1.5}
              mb={3}
            >
              Our happy customers are accelerating their sales growth
            </HeaderTypography>
            <ParagraphTypography
              component="h3"
              fontSize={18}
              lineHeight={1.5}
              mb={3}
            >
              Many businesses trust Pepper Cloud CRM software to empower their
              sales team and run their operations globally. Know about our
              clients' experiences in their words.
            </ParagraphTypography>
          </Box>
        </Box>
        <Container>
          <SingleTestimonial {...client} />
        </Container>
        {/*</Hidden>*/}
      </Container>
      <div className={classes.integrationRoot}>
        <Container>
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Hidden smDown>
                <Box textAlign="center">
                  <StaticImage
                    alt="CRM Integrations"
                    className={classes.img}
                    placeholder="blurred"
                    src="../images/v2/home/integration-logo@3x.webp"
                  />
                </Box>
              </Hidden>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <HeaderTypography
                color="common.white"
                component="h2"
                fontWeight={600}
                mb={0}
                mt={2}
                textAlign="left"
              >
                Integrated CRM
              </HeaderTypography>
              <ParagraphTypography
                color="common.white"
                fontSize={18}
                mb={4}
                mt={1}
                pr={3}
                textAlign="left"
              >
                Exclusive plug-and-play integrations for seamless connections.
                Integrate with Mailchimp, Gmail, Outlook, WhatsApp, Facebook,
                Xero and many more of your favourite tools and arm your team
                with an all-in-one CRM platform.
              </ParagraphTypography>
              <Hidden mdUp>
                <Box mt={4} textAlign="center">
                  <StaticImage
                    alt="CRM integrated with WhatsApp, Facebook, Xero, Gmail, Mailchimp, Drive, Outlook, Zoom, and calendar"
                    className={classes.img}
                    placeholder="blurred"
                    src="../images/v2/home/integration-logo@3x.webp"
                  />
                </Box>
              </Hidden>
              <Box textAlign="left">
                <WhiteButton
                  color="primary"
                  component={Link}
                  size="large"
                  to="/integration"
                  variant="contained"
                >
                  Explore all integrations
                </WhiteButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    featureEngage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-engage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureManage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-manage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureExpand: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-expand@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureAutomate: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-automate@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.object,
};

export default Home;
